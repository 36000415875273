/**
 * Path-related utility functions.
 */

/**
 * Removes all slashes from start and end of the path.
 *
 * @param path a/directory/or/url/path
 * @return Slash-trimmed path.
 */
export const trimPath = (path: string) => path.trim().replace(/^\/+|\/+$/g, "");

/**
 * The filename from a path (basically, the last chunk of the path).
 * Example input: wisdom_archive/us_presidents/barack_obama.txt
 * Example output: barack_obama.txt
 *
 * @param path a/directory/or/url/path
 * @return Extracted filename.
 */
export const extractFilename = (stringifiedPath: string) =>
  trimPath(stringifiedPath).replace(/^.*[\\/]/, "");

/**
 * Takes an object and turns it into an URL string. Used to make GET requests.
 *
 * @param data Data to encode.
 * @returns URL encoded string.
 */
export const encodeUrlData = (data: Record<string, string>) => {
  return Object.keys(data)
    .map((key: string) => {
      return [key, data[key]].map(encodeURIComponent).join("=");
    })
    .join("&");
};
