import React, { useEffect } from "react";

import List from "./tree/components/List";
import FetchChildrenContext from "./tree/context/FetchChildren";
import { IElement } from "./tree/struct";
import { getNode } from "./useTreeControls/helpers";

interface IProps {
  tree: IElement;
  fetchChildren: (path: string) => void | Promise<void>;
  basePath?: string;
  baseUrl: string;
}

export const Tree = ({
  tree: fullTree,
  basePath: path = "",
  fetchChildren,
  baseUrl,
}: IProps) => {
  const tree = getNode(fullTree, path);
  useEffect(() => {
    if (!tree?.childrenFullyFetched && fetchChildren) {
      fetchChildren(path);
    }
  }, [fetchChildren, path, tree?.childrenFullyFetched]);

  if (tree?.childrenFullyFetched !== true) {
    return <LoadingIndicator />;
  }

  if (tree.children === undefined) {
    return <LoadingIndicator />;
  }

  return (
    <FetchChildrenContext.Provider value={fetchChildren}>
      <List
        elements={tree.children}
        path={path}
        isRoot={true}
        baseUrl={baseUrl}
      />
    </FetchChildrenContext.Provider>
  );
};

const LoadingIndicator = () => <h1>Loading...</h1>;
