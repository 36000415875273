import { useCallback } from "react";

import { IDataProvider } from "./data-providers/struct";
import { IObject } from "./struct";
import { ITreeControls } from "./useTreeControls";

export const useFetchChildren = (
  { setChildren, setLoading }: ITreeControls<IObject>,
  dataProvider: IDataProvider
) =>
  useCallback(
    async (path: string) => {
      const requestObjects = await dataProvider.getObjects(path);
      setLoading(path, true);
      setChildren(path, requestObjects);
      setLoading(path, false);
    },

    [dataProvider, setChildren, setLoading]
  );
