import { memo, useState } from "react";

import { useMouseOver } from "../helpers/useMouseOver";

import { IElement, Styles } from "../struct";
import Icon from "./Icon";
import List from "./List";
import { useFetchChildren } from "../helpers/useFetchChildren";

interface IProps {
  element: IElement;
  path: string;
  safePath: string;
  baseUrl: string;
}

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export default memo(({ element, path, baseUrl, safePath }: IProps) => {
  // Hooks
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { isMouseOver, ...mouseOverEvents } = useMouseOver();
  const fetchChildren = useFetchChildren(path);

  // Callbacks
  const onClickDirectory = async () => {
    if (!isExpanded && !childrenFullyFetched) {
      await fetchChildren();
    }

    setIsExpanded((oldIsExpanded) => !oldIsExpanded);
  };

  const onClickFile = () => {
    if (element.data) {
      openInNewTab(`${baseUrl}/${safePath}`);
    }
  };

  const onClick = () => {
    if (isDirectory) {
      onClickDirectory();
    } else {
      onClickFile();
    }
  };

  // Data processing
  const { children, childrenFullyFetched, data, isLoading } = element;
  if (data === undefined) {
    throw new Error(`Could not find data for path "${path}".`);
  }

  const { isDirectory, name } = data;

  // Styling
  const style = {
    ...styles.element,
    ...(isMouseOver ? styles.elementHovered : {}),
  };

  // Render
  return (
    <li>
      <a {...{ onClick }} {...{ style }} {...mouseOverEvents}>
        <Icon {...{ isDirectory }} {...{ isExpanded }} {...{ isLoading }} />
        <span style={styles.name}>{name}</span>
      </a>
      {children !== undefined && isExpanded && (
        <List
          {...{ path }}
          {...{ elements: children }}
          {...{ fetchChildren }}
          baseUrl={baseUrl}
        />
      )}
    </li>
  );
});

const styles: Styles = {
  element: {
    display: "block",
    padding: "0 10px 0 10px",
    lineHeight: "2.5em",
    fontSize: "16px",
    borderBottom: "1px solid #f0f0f0",
    textDecoration: "none",
    color: "#000000",
    userSelect: "none",
  },
  elementHovered: {
    cursor: "pointer",
  },
  name: {
    paddingLeft: 10,
  },
};
