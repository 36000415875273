import { useTreeControls, ITreeControls } from "./useTreeControls";

import { IDataProvider } from "../src/data-providers/struct";
import { IObject } from "./struct";
import { Tree } from "./Tree";
import { createDataProvider as createAmazonDataProvider } from "./data-providers/amazon";
import { useFetchChildren } from "./useFetchChildren";

type AvailableServiceProviders = "Amazon";

interface IProps {
  serviceProvider?: AvailableServiceProviders;
  bucketName?: string;
  region?: string;
  dataProvider?: IDataProvider;
  treeControls?: ITreeControls<IObject>;
  basePath?: string;
}

export const App = ({
  serviceProvider,
  bucketName,
  region,
  dataProvider: dataProviderParent,
  basePath,
}: IProps) => {
  const dataProvider =
    dataProviderParent ??
    createDataProvider(serviceProvider, bucketName, region);

  const treeControls = useTreeControls<IObject>();
  const fetchChildren = useFetchChildren(treeControls, dataProvider);

  const baseUrl = `https://s3.${region}.amazonaws.com/${bucketName}`;

  return (
    <Tree
      tree={treeControls.tree}
      fetchChildren={fetchChildren}
      basePath={basePath}
      baseUrl={baseUrl}
    />
  );
};

const createDataProvider = (
  serviceProvider?: AvailableServiceProviders,
  bucketName?: string,
  region?: string
) => {
  switch (serviceProvider) {
    case "Amazon":
      if (bucketName && region) {
        return createAmazonDataProvider(bucketName, region);
      } else {
        throw new Error(
          'Amazon service requires both "bucketName" and "region" fields.'
        );
      }
    default:
      throw new Error(`Invalid service provider supplied: ${serviceProvider}`);
  }
};
