import { IGhostNode, INode } from './struct'

export const createGhostNode = <T>(parent?: INode<T>): IGhostNode<T> => ({
  data: undefined,
  parent,
  children: undefined,
  childrenFullyFetched: false,
  isLoading: false,
  isGhost: true,
})

export const mutate = <T>(
  state: INode<T>,
  path: string,
  mutator: (node: INode<T>) => INode<T>,
  strictNodeCheck: boolean = false,
): INode<T> =>
  path.length === 0
    ? mutator(state)
    : internalMutate(state, pathToArray(path), mutator, strictNodeCheck)

export const getNode = <T>(state: INode<T>, path: string): INode<T> | undefined =>
  internalGetNode(pathToArray(path), state)

const pathToArray = (path: string) => (path.length === 0 ? [] : path.split('/'))

const internalMutate = <T>(
  state: INode<T>,
  path: string[],
  mutator: (node: INode<T>) => INode<T>,
  strictNodeCheck: boolean,
): INode<T> => {
  const [key, ...childPath] = path

  // Mutate node
  const originalNode =
    (state.children ? state.children[key] : undefined) ??
    (strictNodeCheck ? undefined : createGhostNode(state))

  if (!originalNode) {
    throw new TypeError(`Cannot find path for mutation: ${path.join('/')}`)
  }

  const mutatedNode =
    childPath.length === 0
      ? mutator(originalNode)
      : internalMutate(originalNode, childPath, mutator, strictNodeCheck)

  // Mutate state children
  return {
    ...state,
    children: {
      ...(state.children ?? {}),
      [key]: mutatedNode,
    },
  }
}

const internalGetNode = <T>(path: string[], state?: INode<T>): INode<T> | undefined => {
  if (path.length === 0) {
    return state
  }

  const [key, ...childPath] = path
  const currentNode = state?.children?.[key]

  return internalGetNode(childPath, currentNode)
}
