/**
 * AWS S3 API data provider abstraction layer.
 */

import { S3 } from 'aws-sdk'

import { extractFilename } from '../helpers/path'
import { IObject } from '../../struct'
import { IRequest, IResponse, IResponseDirectory, IResponseError, IResponseFile } from './struct'

/**
 * Name of action to list objects from a bucket.
 */
const APIListOperation = 'listObjectsV2'

/**
 * Makes the actual request to Amazon S3. Not limited to directory listing,
 * can handle any request from S3 which does not involve authentication.
 * Handling private buckets is unsupported at the moment.
 *
 * @param S3 AWS S3 API instance.
 * @param operation Name of desired action to send to Amazon.
 * @param params Operation parameters (API request body).
 * @return API response.
 */
const backendRequest = <REQ, RES>(S3Instance: S3, operation: string, params: REQ): Promise<RES> =>
  new Promise((resolve, reject) => {
    S3Instance.makeUnauthenticatedRequest(operation, params, (error: IResponseError, data: RES) => {
      error ? reject(`[Code ${error.code}]: ${error.message}`) : resolve(data)
    })
  })

/**
 * Retrieves object listing from API.
 *
 * @param S3 AWS S3 API instance.
 * @param bucket Bucket name.
 * @param path Parent of objects to retrieve.
 * @param continuationToken Token given by API to retrieve the next set of
 *  results, in case the response is truncated.
 * @return S3 object listing API response.
 */
const listObjects = async (
  S3Instance: AWS.S3,
  bucket: string,
  path: string,
  continuationToken?: string,
): Promise<IResponse> =>
  backendRequest<IRequest, IResponse>(S3Instance, APIListOperation, {
    Bucket: bucket,
    ContinuationToken: continuationToken,
    Delimiter: '/',
    Prefix: path.length === 0 ? '' : `${path}/`,
  })

const parseResponseFile = ({ Key: path, Size: size }: IResponseFile): IObject => {
  const name = extractFilename(path)

  return {
    isDirectory: false,
    key: name,
    name,
    size,
  }
}

const parseResponseDirectory = ({ Prefix: path }: IResponseDirectory): IObject => {
  const name = extractFilename(path)

  return {
    isDirectory: true,
    key: name,
    name,
    size: 0,
  }
}

const parseResponse = (response: IResponse) => [
  ...response.CommonPrefixes.map(parseResponseDirectory),
  ...response.Contents.map(parseResponseFile),
]

/**
 * Retrieves data from AWS S3 and creates local response data structures from
 * them.
 *
 * @param S3 AWS S3 API instance.
 * @param bucket AWS S3 bucket name.
 * @param path Parent of objects to retrieve.
 * @param continuationToken Token given by API to retrieve the next set of
 *  results, in case the response is truncated.
 * @param previousElements In case the response was truncated and this is not
 *  the first request for this action, we recursively send the concatenated
 *  list of previous "pages".
 * @returns Files and directories list.
 */
const getObjects = async (
  S3Instance: S3,
  bucket: string,
  path: string,
  continuationToken?: string,
  previousElements: IObject[] = [],
): Promise<IObject[]> => {
  const response = await listObjects(S3Instance, bucket, path, continuationToken)
  const elements = [...previousElements, ...parseResponse(response)]

  return response.IsTruncated
    ? getObjects(S3Instance, bucket, path, response.NextContinuationToken, elements)
    : elements
}

export default getObjects
