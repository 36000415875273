/**
 * Root point of the data provider. It retrieves data from the server.
 */

import { config, S3 } from "aws-sdk";

import { IDataProvider } from "../struct";
import { KeyedList, IObject } from "../../struct";
import getObjects from "./getObjects";

/**
 * Factory method to create a data provider, including S3 abstraction layer and
 * helper methods.
 *
 * @param bucket S3 bucket name
 * @param region S3 region
 * @return An instasnce of the data provider.
 */
export const createDataProvider = (
  bucket: string,
  region: string
): IDataProvider => {
  config.update({ region });
  const S3Instance = new S3({ apiVersion: "2006-03-01" });

  return {
    getObjects: async (path: string) =>
      withKeys(getObjects(S3Instance, bucket, path)),
    getObjectUrl: (path: string) =>
      `https://${bucket}.s3.${region}.amazonaws.com/${encodeURI(path)}`,
  };
};

const withKeys = async (
  objects: Promise<IObject[]>
): Promise<KeyedList<IObject>> =>
  (await objects).reduce(
    (acc: KeyedList<IObject>, objectEnty: IObject) => ({
      ...acc,
      [objectEnty.name]: objectEnty,
    }),
    {}
  );
