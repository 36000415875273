import { useCallback, useReducer, Reducer as ReducerDefinition } from "react";

import { KeyedList } from "./struct";
import { mutate, createGhostNode } from "./useTreeControls/helpers";
import { INode, INodes, IRealNode } from "./useTreeControls/struct";

export interface ITreeControls<T> {
  tree: INode<T>;
  setChildren: (
    path: string,
    children: KeyedList<T>,
    preserveSubtree?: boolean
  ) => void;
  setData: (path: string, data: T) => void;
  setLoading: (path: string, isLoading: boolean) => void;
}

export interface ITreeControls<T> {
  setChildren: (
    path: string,
    children: KeyedList<T>,
    preserveSubtree?: boolean
  ) => void;
  setData: (path: string, data: T) => void;
  setLoading: (path: string, isLoading: boolean) => void;
  tree: INode<T>;
}

export const useTreeControls = <T>() => {
  const [tree, dispatchTree] = useReducer<
    ReducerDefinition<INode<T>, IReducerAction<T>>
  >(reducer, createGhostNode<T>());

  const setChildren = useCallback(
    (path: string, children: KeyedList<T>, preserveSubtree: boolean = true) =>
      dispatchTree({ type: "SetChildren", path, children, preserveSubtree }),
    [dispatchTree]
  );

  const setData = useCallback(
    (path: string, data: T) => dispatchTree({ type: "SetData", path, data }),
    [dispatchTree]
  );

  const setLoading = useCallback(
    (path: string, isLoading: boolean) =>
      dispatchTree({ type: "SetLoading", path, isLoading }),
    [dispatchTree]
  );

  return { setChildren, setData, setLoading, tree };
};

interface BaseReducerAction {
  path: string;
}

interface ISetDataReducerAction<T> extends BaseReducerAction {
  type: "SetData";
  data: T;
}

interface ISetChildrenReducerAction<T> extends BaseReducerAction {
  type: "SetChildren";
  children: KeyedList<T>;
  preserveSubtree: boolean;
}

interface ISetLoadingReducerAction extends BaseReducerAction {
  type: "SetLoading";
  isLoading: boolean;
}

type IReducerAction<T> =
  | ISetDataReducerAction<T>
  | ISetChildrenReducerAction<T>
  | ISetLoadingReducerAction;

type Reducer = <T>(state: INode<T>, action: IReducerAction<T>) => INode<T>;

export const reducer: Reducer = <T>(
  state: INode<T>,
  action: IReducerAction<T>
) => {
  return [setChildrenReducer, setDataReducer, setLoadingReducer].reduce(
    (stateAcc: INode<T>, reducerFunc: Reducer) => reducerFunc(stateAcc, action),
    state
  );
};

const setChildrenReducer: Reducer = <T>(
  state: INode<T>,
  action: IReducerAction<T>
) => {
  if (action.type !== "SetChildren") {
    return state;
  }

  const { children, path, preserveSubtree } = action;

  const nodeMutator = (node: INode<T>) => ({
    ...node,
    childrenFullyFetched: true,
    children: makeNodes(children, preserveSubtree, node),
  });

  return mutate(state, path, nodeMutator);
};

const setDataReducer: Reducer = <T>(
  state: INode<T>,
  action: IReducerAction<T>
) => {
  if (action.type !== "SetData") {
    return state;
  }

  const { path, data } = action;

  if (path.length === 0) {
    throw new Error("Cannot set data on root node.");
  }

  const nodeMutator = (node: INode<T>): IRealNode<T> => ({
    ...node,
    data,
    isGhost: false,
  });

  return mutate(state, path, nodeMutator);
};

const setLoadingReducer: Reducer = <T>(
  state: INode<T>,
  action: IReducerAction<T>
) => {
  if (action.type !== "SetLoading") {
    return state;
  }

  const { path, isLoading } = action;

  const nodeMutator = (node: INode<T>): INode<T> => ({
    ...node,
    isLoading,
  });

  return mutate(state, path, nodeMutator);
};

const makeNode = <T>(
  data: T,
  parent?: INode<T>,
  existingNode?: INode<T>
): INode<T> => ({
  children: existingNode?.children,
  isLoading: existingNode?.isLoading ?? false,
  childrenFullyFetched: existingNode?.childrenFullyFetched ?? false,
  parent,
  data,
  isGhost: false,
});

const makeNodes = <T>(
  dataList: KeyedList<T>,
  preserveSubtree: boolean,
  parent?: INode<T>
): INodes<T> => {
  return Object.entries(dataList).reduce(
    (nodes: INodes<T>, [key, data]) => ({
      ...nodes,
      [key]: makeNode(
        data,
        parent,
        preserveSubtree && parent ? parent.children?.[key] : undefined
      ),
    }),
    {}
  );
};
