/**
 * Component used to display the appropriate icon in front of each element
 * entry. Based on passed props, it can render a file icon, directory icon
 * or a loading indicator.
 */

import React from 'react'

import { faFile } from '@fortawesome/free-regular-svg-icons'
import { faFolder, faFolderOpen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Makes associations between icon types and the actual icon representations
 * from FontAwesome.
 */
const icons = {
  closedDir: faFolder,
  file: faFile,
  loading: faSpinner,
  openDir: faFolderOpen,
}

/**
 * Icon component props.
 */
interface IProps {
  /**
   * Specifies wether the element is a file or a directory.
   */
  readonly isDirectory: boolean

  /**
   * Specifies wether the element is loading its children.
   */
  readonly isLoading: boolean

  /**
   * If element is a directory, specifies wether it is expanded or collapsed.
   */
  readonly isExpanded: boolean
}

/**
 * Takes props and returns an instance of appropriately-configured
 * Font Awesome React component.
 *
 * @param param0 Icon props
 * @returns Font Awesome React component.
 */
const Icon = ({ isDirectory, isLoading, isExpanded }: IProps) => (
  <FontAwesomeIcon icon={getIcon(isDirectory, isLoading, isExpanded)} spin={isLoading} />
)

/**
 * Returns appropriate Font Awesome icon, based on element properties (dir,
 * loading, collapsed).
 *
 * @param isDir Specifies wether the element is a file or a directory.
 * @param isLoading Specifies wether the element is loading its children.
 * @param isCollapsed If element is a directory, specifies wether it is expanded
 *  or collapsed.
 * @returns Font Awesome icon.
 */
const getIcon = (isDirectory: boolean, isLoading: boolean, isExpanded: boolean) =>
  isLoading ? getLoadingIcon() : getElementIcon(isDirectory, isExpanded)

/**
 * Returns wether a file icon or a directory icon (either expanded or collapsed,
 * based on isExpanded argument).
 *
 * @param isDir Specifies wether the element is a file or a directory.
 * @param isExpanded If element is a directory, specifies wether it is expanded
 *  or collapsed.
 * @returns Font Awesome icon.
 */
const getElementIcon = (isDirectory: boolean, isExpanded: boolean) =>
  isDirectory ? getDirIcon(isExpanded) : getFileIcon()

/**
 * Returns a colosed or expanded directory icon.
 *
 * @param isExpanded If element is a directory, specifies wether it is expanded
 *  or collapsed.
 * @returns Font Awesome icon.
 */
const getDirIcon = (isExpanded: boolean) => (isExpanded ? icons.openDir : icons.closedDir)

/**
 * Returns a file icon.
 *
 * @returns Font Awesome icon.
 */
const getFileIcon = () => icons.file

/**
 * Returns a loading indicator icon.
 *
 * @returns Font Awesome icon.
 */
const getLoadingIcon = () => icons.loading

export default Icon
