import React, { createContext } from 'react'

export type IFetchChildrenContext = (path: string) => void | Promise<void>

const defaultFetchChilden = () => undefined

const FetchChildrenContext: React.Context<IFetchChildrenContext> = createContext<
  IFetchChildrenContext
>(defaultFetchChilden)

export default FetchChildrenContext
