import React, { memo } from "react";

import { IElements, Styles } from "../struct";
import Element from "./Element";

interface IProps {
  elements: IElements;
  isRoot?: boolean;
  path: string;
  baseUrl: string;
}

export default memo(({ elements, isRoot = false, path, baseUrl }: IProps) => {
  const makePath = (key: string) =>
    path.length === 0 ? key : `${path}/${key}`;

  const listStyle = {
    ...styles.list,
    ...(isRoot ? styles.rootList : {}),
  };

  const elementComponents = Object.entries(elements).map(([key, element]) => (
    <Element
      {...{ key }}
      {...{ element }}
      path={makePath(key)}
      safePath={makePath(encodeURIComponent(key))}
      baseUrl={baseUrl}
    />
  ));

  return <ul style={listStyle}>{elementComponents}</ul>;
});

const styles: Styles = {
  list: {
    listStyleType: "none",
    margin: "0 0 0 25px",
    padding: 0,
  },
  rootList: {
    marginLeft: 0,
  },
};
